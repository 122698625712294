import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core'
import { NgFor, NgIf } from '@angular/common'
import { TooltipDirective } from '../../../directives/tooltip/tooltip.directive'
import { FileIconComponent } from '../../files/file-icon/file-icon.component'
import { BadgeComponent } from '../../data-display/badge/badge.component'
import { FileUpload } from '@awork/_shared/models/file-upload.model'
import { ExternalProviders, FileExternal } from '@awork/_shared/models/file-external.model'
import { Color } from '@awork/_shared/types/color'
import { Size } from '@awork/_shared/types/size'
import { IconButtonComponent } from '../../icon-buttons/icon-button/icon-button.component'

interface FileInfo {
  identifier: string
  name: string
  extension: string
  externalProvider: ExternalProviders
  type: 'external' | 'local'
}

/**
 * Extract the information necessary to render the files information
 * @param files
 */
function mapFiles(files: (FileUpload | FileExternal)[]): FileInfo[] {
  return files.map(file => ({
    identifier: (file as FileUpload).id || file.externalFileUrl,
    name: file.name,
    extension: FileUpload.getFileExtension(file.name),
    externalProvider: file.externalProvider,
    type: (file as FileUpload).id ? 'local' : 'external'
  }))
}

@Component({
  selector: 'aw-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss', '../styles.scss'],
  standalone: true,
  imports: [NgIf, NgFor, TooltipDirective, FileIconComponent, BadgeComponent, IconButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileFieldComponent {
  @Input({ transform: mapFiles }) files: (FileUpload | FileExternal)[] = []
  @Input() label: string
  @Input() removable: boolean = true

  @Output() onUpload: EventEmitter<Event> = new EventEmitter<Event>()
  @Output() onRemove: EventEmitter<FileInfo> = new EventEmitter<FileInfo>()

  protected readonly sizes = Size
  protected readonly colors = Color
}
